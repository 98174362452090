import React, { useEffect, useRef, useState } from 'react';
// import MetaTags from 'react-meta-tags';

// UI component import
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback } from 'reactstrap';

// parent component (theme import)
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

// navigation import
import { useNavigate, Link } from 'react-router-dom';

// images
// import logoLight from "../../assets/images/logo-light.png";

// toast messages
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// firebase
import { app, database } from "../../config";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { collection, getDocs, query, where } from "firebase/firestore";

// redux
import { useDispatch } from "react-redux";
import { initializeUser } from "../../features/user/userSlice";
import { addApprovedSightingLocations } from "../../features/sightingLocation/approvedsightingLocationSlice";
import { addRejectedSightingLocations } from "../../features/sightingLocation/rejectedSightingLocationSlice";
import { addPendingSightingLocations } from '../../features/sightingLocation/pendingSightingLocation';

// formik form handling
import { useFormik } from "formik";
import { loginSchema } from "../../schema/loginSchema";

// initial values for formik
const initialValues = {
    email: "",
    password: "",
};


const Login = () => {
    // to toggle password field as text and password (show password on eye button click)
    const [showPassword,setShowPassword] = useState(false);

    // alternative of a tag in html
    const navigate = useNavigate();

    // initialize firebase database
    const auth = getAuth(app);

    // Used for initial input focus on email input tag
    const initialInputRef = useRef(); 

    // used to dispatch redux actions
    const dispatch = useDispatch();

    // initialize approved and rejected sighting in store(redux)
    const initializeSightingsInStore = async (userEmail) => {
      // creating approved sightings collection ref
      const approvedSightingRef = collection(database, "approved sightings");

      // adding query for loggedIn(current) user approved docs
      const approvedQuery = query(
        approvedSightingRef,
        where("user", "==", userEmail)
      );

      // fetching all approved docs of loggedIn(current) user from firebase database
      const approvedDocuments = await getDocs(approvedQuery);
      
      // storing all approved sightings of current user in store(redux) one by one
      approvedDocuments.forEach((doc) => {
        const approvedSight = doc.data();
        const { latitude, longitude, city, state } = approvedSight.location_details;
        const { habitat, numbers_observed, activity, date, spotted_at, species, type_of_sighting, time } = approvedSight.sighting_details;
        const { title } = approvedSight;
        const tempSight = {
          title,
          docid: doc.id,
          lat: latitude,
          lng: longitude,
          city,
          state,
          habitat,
          numbers_observed,
          activity,
          date,
          spotted_at,
          time,
          species,
          type_of_sighting,
          type:1
        };
        dispatch(addApprovedSightingLocations(tempSight));
      });

      // initialize rejected sighting in store(redux)
      // creating rejected sightings collection ref
      const rejectedSightingRef = collection(database, "rejected sightings");
      
      // adding query for loggedIn(current) user rejected docs
      const rejectedQuery = query(
        rejectedSightingRef,
        where("user", "==", userEmail)
      );

      // fetching all rejected docs of current(LoggedIn) user
      const rejectedDocuments = await getDocs(rejectedQuery);

      // storing all rejected sightings of current user in store(redux) one by one
      rejectedDocuments.forEach((doc) => {
        const rejectedSight = doc.data();
        const { latitude, longitude, city, state } = rejectedSight.location_details;
        const { habitat, numbers_observed, activity, time, date, spotted_at, species, type_of_sighting } = rejectedSight.sighting_details;
        const { title } = rejectedSight;
        const tempSight = {
          title,
          docid: doc.id,
          lat: latitude,
          lng: longitude,
          city,
          state,
          habitat,
          numbers_observed,
          activity,
          date,
          time,
          spotted_at,
          species,
          type_of_sighting,
          type:2
        };
        dispatch(addRejectedSightingLocations(tempSight));
      })
    }

    // initialize pending sight in store function
    // this will be called only if userType is moderator
    const initializePendingSightInStore = async () => {
      // creating pendind docs collection ref
      const pendingSightingRef = collection(database, "unapproved sightings");
      const pendingSightingQuery = query(pendingSightingRef);

      // fetching all pending docs from database
      const pendingDocuments = await getDocs(pendingSightingQuery);

      // initializing all pending doc in store
      // all documents will be initialized one by one in store
      pendingDocuments.forEach((doc) => {
        const pendingSight = doc.data();
        const { latitude, longitude, city, state } = pendingSight.location_details;
        const { habitat, numbers_observed, activity, time, date, spotted_at, species, type_of_sighting } = pendingSight.sighting_details;
        const { title, user } = pendingSight;
        const tempSight = {
          title,
          docid: doc.id,
          lat: latitude,
          lng: longitude,
          city,
          state,
          habitat,
          numbers_observed,
          activity,
          date,
          time,
          spotted_at,
          species,
          type_of_sighting,
          user,
          type:4
        };
        dispatch(addPendingSightingLocations(tempSight));
      });
      return;
    }

    // initialize user data in store
    const updateUserDataInStore = async (userEmail) => {
      // creating user ref
      const userRef = collection(database, "user");
      const queryRef = query(userRef, where("email", "==", userEmail));

      // fetch user data from firebase database (getDoc will work better)
      const querySnapshot = await getDocs(queryRef);
      let userData;
      querySnapshot.forEach((doc) => {
        userData = doc.data()
      });

      console.log("user IMAGE: ", userData.profilepic)

      // extracting user Info from user data
      const { firstname, lastname, profilepic, role, userbio, mobilenumber, socialmedia } = userData;
      let user = {
        firstName:firstname,
        lastName:lastname,
        userBio: userbio,
        userRole: role,
        profileImage: profilepic,
        userEmail:userEmail,
        userPhone: mobilenumber,
        socialmedia: socialmedia
      };

      // initializing user in store via action
      dispatch(initializeUser(user));

      // if user is moderator or admin then get unapproved (pending) sightings will be initialized in store (redux)
      if(role == "Moderator" || role == "admin"){
        await initializePendingSightInStore();
      }
      return;
    };

    // formik form handling
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: loginSchema,
      onSubmit: (values, action) => {
        // firebase signIn functionality
        signInWithEmailAndPassword(auth, values.email, values.password)
        .then((response) => {
          const userEmail = response.user.email; // extract emailId from response

          // toast message for successful user login
          toast(`Logged In as ${userEmail}`, {
            position: "top-center",
          });

          // initialize user data in store(redux) and initalize pending sight if user is moderator
          updateUserDataInStore(userEmail);

          // initialize approved and pending sight in store
          initializeSightingsInStore(userEmail);

          // change url to home
          navigate("/")
        })
        .catch((error) => {
          toast("Wrong email or password", { position: "top-center" });
        });
        action.resetForm(); // remove entered values and make inputs empty
      },
    });

    // initial input focus on email input tag when page renders
    useEffect(() => {
      initialInputRef.current.focus();
    }, []);

    return (
      <>
        <ParticlesAuth>
          <div className="auth-page-content">

              {/* <MetaTags>
                  <title>VNC CrocWatch | Login</title>
              </MetaTags> */}
              <Container>
                  <Row>
                      <Col lg={12}>
                          <div className="text-center mt-sm-5 mb-4 text-white-50">
                              <div>
                                  <Link to="/" className="d-inline-block auth-logo">
                                      <img src="crocwatch.png" alt="" height="70" />
                                  </Link>
                              </div>
                              {/* <p className="mt-3 fs-20 fw-medium">VNC CrocWatch Login Page</p> */}
                          </div>
                      </Col>
                  </Row>

                  <Row className="justify-content-center">
                      <Col md={8} lg={6} xl={5}>
                          <Card className="mt-4">
                              <CardBody className="p-4">
                                  <div className="text-center mt-2">
                                      <h5 className="text-primary">Welcome Back!</h5>
                                      <p className="text-muted">Login to continue</p>
                                  </div>
                                  <div className="p-2 mt-4">
                                      <Form
                                          onSubmit={(e) => {
                                              e.preventDefault();
                                              // handleSubmit();
                                              return false;
                                          }}
                                          action="#">

                                          <div className="mb-3">
                                              <Label htmlFor="email" className="form-label">Email</Label>
                                              <Input
                                                  name="email"
                                                  className="form-control"
                                                  placeholder="Enter email"
                                                  type="email"
                                                  ref={initialInputRef}
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  value={values.email || ""}
                                                  invalid={
                                                      touched.email && errors.email ? true : false
                                                  }
                                              />
                                              {touched.email && errors.email ? (
                                                  <FormFeedback type="invalid">{errors.email}</FormFeedback>
                                              ) : null}
                                          </div>

                                          <div className="mb-3">
                                              <div className="float-end">
                                                  <Link to="/forgot-password" className="text-muted">Forgot password?</Link>
                                              </div>
                                              <Label className="form-label" htmlFor="password-input">Password</Label>
                                              <div className="position-relative auth-pass-inputgroup mb-3">
                                                  <Input
                                                      name="password"
                                                      value={values.password || ""}
                                                      type={showPassword?"text":"password"}
                                                      className="form-control pe-5"
                                                      placeholder="Enter Password"
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      invalid={
                                                          touched.password && errors.password ? true : false
                                                      }
                                                  />
                                                  {touched.password && errors.password ? (
                                                      <FormFeedback type="invalid">{errors.password}</FormFeedback>
                                                  ) : null}
                                                  <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" onClick={e => setShowPassword(!showPassword)} id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                                              </div>
                                          </div>

                                          {/* <div className="form-check">
                                              <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                              <Label className="form-check-label" htmlFor="auth-remember-check">Remember me</Label>
                                          </div> */}

                                          <div className="mt-4">
                                              <Button color="success" onClick={handleSubmit} className="btn btn-success w-100" type="submit">Sign In</Button>
                                          </div>

                                          {/* <div className="mt-4 text-center">
                                              <div className="signin-other-title">
                                                  <h5 className="fs-13 mb-4 title">Sign In with</h5>
                                              </div>
                                              <div>
                                                    <FacebookLogin
                                                      appId={facebook.APP_ID}
                                                      autoLoad={false}
                                                      callback={facebookResponse}
                                                      render={renderProps => (
                                                          <Button color="primary"
                                                              className="btn-icon me-1"
                                                              onClick={renderProps.onClick}
                                                          >
                                                              <i className="ri-facebook-fill fs-16" />
                                                          </Button>
                                                      )}
                                                  />
                                                  <GoogleLogin
                                                      clientId={
                                                          google.CLIENT_ID ? google.CLIENT_ID : ""
                                                      }
                                                      render={renderProps => (
                                                          <Button color="danger"
                                                              to="#"
                                                              className="btn-icon me-1"
                                                              onClick={renderProps.onClick}
                                                          >
                                                              <i className="ri-google-fill fs-16" />
                                                          </Button>
                                                      )}
                                                      onSuccess={googleResponse}
                                                      onFailure={() => {

                                                      }}
                                                  /> 
                                                    <Button color="dark" className="btn-icon"><i className="ri-github-fill fs-16"></i></Button>{" "} 
                                                    <Button color="info" className="btn-icon"><i className="ri-twitter-fill fs-16"></i></Button> 
                                              </div>
                                          </div> */}
                                      </Form>
                                  </div>
                              </CardBody>
                          </Card>

                          <div className="mt-4 text-center">
                              <p className="mb-0">Don't have an account ? <Link to="/signup" className="fw-semibold text-primary text-decoration-underline"> Signup </Link> </p>
                          </div>

                      </Col>
                  </Row>
              </Container>
          </div>
        </ParticlesAuth>
      </>
    )}

export default Login;