import { createSlice } from "@reduxjs/toolkit";

export const approvedSightingLocationSlice = createSlice({
    name: 'approvedSightingLocation',
    initialState: {
        approvedSightings:[],
        count:0
    },
    reducers: {
        addApprovedSightingLocations: (state,action) => {
            return{
                ...state,
                approvedSightings:[...state.approvedSightings,action.payload],
                count:state.approvedSightings.length + 1,
            }
        },
        removeApprovedSightingLocation: (state,payload) => {
            return{
                ...state,
                todos: state.approvedSightings.filter((loc) => loc.lat !== payload.lat && loc.lng !== payload.lng)
            }
        },
        logOutAndRemoveAllApprovedSightings: (state) => {
            return {
                ...state,
                approvedSightings:[],
                count:0
            }
        }
      }
  })
  
  // Action creators are generated for each case reducer function
  export const { addApprovedSightingLocations,removeApprovedSightingLocation,logOutAndRemoveAllApprovedSightings } = approvedSightingLocationSlice.actions
  export default approvedSightingLocationSlice.reducer