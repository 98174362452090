import React, {useEffect, useRef, useState } from 'react'

// import MetaTags from "react-meta-tags";

// UI component import
import { Row, Col, CardBody, Card, Container, Input, Label, Form, FormFeedback } from "reactstrap";

// a tag in react
import { Link } from "react-router-dom";

// toast messages
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// firebase
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { app, database } from "../../config";
import { doc,setDoc } from "firebase/firestore";

// redux
import { useDispatch } from "react-redux";
import { initializeUser } from "../../features/user/userSlice";


//import images 
// import logoLight from "../../assets/images/logo-light.png";

// parent component (theme import)
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

// formik form handling
import { useFormik } from "formik";
import { signUpSchema } from "../../schema/signUpSchema";

// initial values for signup form
const initialValues = {
    firstName:"",
    lastName:"",
    email: "",
    password: ""
};

const Register = () => {
    // to toggle password field as text and password (show password on eye button click)
    const [showPassword, setShowPassword] = useState(false);

    // initialize firebase database
    const auth = getAuth(app)

    // initialize redux actions
    const dispatch = useDispatch();

    // Used for initial input focus on email input tag
    const initialInputRef = useRef(); 

    // formik form handling
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: signUpSchema,
      onSubmit:async (values, action) => {
        // create user in firebase authentication
        createUserWithEmailAndPassword(auth,values.email,values.password)
        .then((userCredentials) => {
            console.log(userCredentials.user)
        })

        // creating user object as firebase database requirment
        const userDataForFirebase = {
            email:values.email,
            firstname:values.firstName,
            lastname:values.lastName,
            mobilenumber:"",
            role:'Participent',
            profilepic:'user.jpg',
            userbio:'Nature Lover',
            socialmedia: {
                Instagram:"",
                Linkedin:"",
                facebook:""
            }
        }

        // creating user object as store(redux) requirment
        const userDataForStore = {
            firstName:values.firstName,
            lastName:values.lastName,
            userEmail:values.email,
            userBio:'Nature Lover',
            userRole:'Participent',
            userEmail: values.email,
            profileImage:'user.jpg',
            userPhone:"",
            socialmedia: {
                facebook:"",
                Linkedin:"",
                Instagram:""
            }
        }

        // creating user in firebase database
        await setDoc(doc(database, "user", values.email), userDataForFirebase);
        
        // initialize user in store(redux)
        dispatch(initializeUser(userDataForStore))
        
        // toast message
        toast("User created successfully",{"position":"top-right"})

        action.resetForm(); // remove entered values and make inputs empty
      },
    });

  // Used for initial input focus on first name input tag
  useEffect(() => {
    initialInputRef.current.focus();
  }, []);

    // const validation = useFormik({
    //     // enableReinitialize : use this flag when initial values needs to be changed
    //     enableReinitialize: true,

    //     initialValues: {
    //         email: '',
    //         username: '',
    //         password: '',
    //     },
    //     validationSchema: Yup.object({
    //         email: Yup.string().required("Please Enter Your Email"),
    //         username: Yup.string().required("Please Enter Your Username"),
    //         password: Yup.string().required("Please Enter Your Password"),
    //     }),
    //     onSubmit: (values) => {
    //         dispatch(registerUser(values));
    //     }
    // });

    // const { user, registrationError } = useSelector(state => ({
    //     user: state.Account.user,
    //     registrationError: state.Account.registrationError,
    // }));

    // useEffect(() => {
    //     dispatch(apiError(""));
    // }, [dispatch]);

    return (
        <>
            <ParticlesAuth>
                <div className="auth-page-content">
                    {/* <MetaTags>
                        <title>Basic SignUp | Velzon - React Admin & Dashboard Template</title>
                    </MetaTags> */}
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src="crocwatch.png" alt="" height="70" />
                                        </Link>
                                    </div>
                                    {/* <p className="mt-3 fs-20 fw-medium">VNC CrocWatch Sign Up Page</p> */}
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">

                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Create New Account</h5>
                                            <p className="text-muted">Get your crocWatch account now</p>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <Form>
                                                <div className="mb-3">
                                                    <Label htmlFor="useremail" className="form-label">Email <span className="text-danger">*</span></Label>
                                                    <Input
                                                        id="email"
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Enter email address"
                                                        type="email"
                                                        ref={initialInputRef}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.email || ""}
                                                        invalid={
                                                            touched.email && errors.email ? true : false
                                                        }
                                                    />
                                                    {touched.email && errors.email ? (
                                                        <FormFeedback type="invalid"><div>{errors.email}</div></FormFeedback>
                                                    ) : null}
                                                    {/* <div className="invalid-feedback">
                                                        Please enter email
                                                    </div> */}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="firstName" className="form-label">First Name <span className="text-danger">*</span></Label>
                                                    <Input
                                                        name="firstName"
                                                        type="text"
                                                        placeholder="First Name"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.firstName || ""}
                                                        invalid={
                                                            touched.firstName && errors.firstName ? true : false
                                                        }
                                                    />
                                                    {touched.firstName && errors.firstName ? (
                                                        <FormFeedback type="invalid"><div>{errors.firstName}</div></FormFeedback>
                                                    ) : null}
                                                    {/* <div className="invalid-feedback">
                                                        Please enter first name
                                                    </div> */}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="lastName" className="form-label">Last Name <span className="text-danger">*</span></Label>
                                                    <Input
                                                        name="lastName"
                                                        type="text"
                                                        placeholder="Last Name"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.lastName || ""}
                                                        invalid={
                                                            touched.lastName && errors.lastName ? true : false
                                                        }
                                                    />
                                                    {touched.lastName && errors.lastName ? (
                                                        <FormFeedback type="invalid"><div>{errors.lastName}</div></FormFeedback>
                                                    ) : null}
                                                    {/* <div className="invalid-feedback">
                                                        Please enter last name
                                                    </div> */}
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="password" className="form-label">Password <span className="text-danger">*</span></Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3"> 
                                                        <Input
                                                            name="password"
                                                            type={showPassword?"text":"password"}
                                                            placeholder="Enter Password"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.password || ""}
                                                            invalid={
                                                                touched.password && errors.password ? true : false
                                                            }
                                                        />
                                                        {touched.password && errors.password ? (
                                                            <FormFeedback type="invalid"><div>{errors.password}</div></FormFeedback>
                                                        ) : null}
                                                        <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" onClick={e => setShowPassword(!showPassword)} id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                                                    </div>
                                                    {/* <div className="invalid-feedback">
                                                        Please enter password
                                                    </div> */}
                                                </div>

                                                {/* <div className="mb-4">
                                                    <p className="mb-0 fs-12 text-muted fst-italic">By registering you agree to the Velzon 
                                                    <Link to="#" className="text-primary text-decoration-underline fst-normal fw-medium">Terms of Use</Link></p>
                                                </div> */}

                                                <div className="mt-4">
                                                    <button className="btn btn-success w-100" type="submit" onClick={handleSubmit}>Sign Up</button>
                                                </div>

                                                {/* <div className="mt-4 text-center">
                                                    <div className="signin-other-title">
                                                        <h5 className="fs-13 mb-4 title text-muted">Create account with</h5>
                                                    </div>

                                                    <div>
                                                        <button type="button" className="btn btn-primary btn-icon waves-effect waves-light"><i className="ri-facebook-fill fs-16"></i></button>{" "}
                                                        <button type="button" className="btn btn-danger btn-icon waves-effect waves-light"><i className="ri-google-fill fs-16"></i></button>{" "}
                                                        <button type="button" className="btn btn-dark btn-icon waves-effect waves-light"><i className="ri-github-fill fs-16"></i></button>{" "}
                                                        <button type="button" className="btn btn-info btn-icon waves-effect waves-light"><i className="ri-twitter-fill fs-16"></i></button>
                                                    </div>
                                                </div> */}
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>

                                <div className="mt-4 text-center">
                                    <p className="mb-0">Already have an account ? <Link to="/login" className="fw-semibold text-primary text-decoration-underline"> Signin </Link> </p>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </>
    );
};

export default Register;
