import { createSlice } from "@reduxjs/toolkit";

export const pendingSightingLocationSlide = createSlice({
    name: 'pendingSightingLocation',
    initialState: {
        pendingSightings:[],
        count:0
    },
    reducers: {
        addPendingSightingLocations: (state,action) => {
            return{
                ...state,
                pendingSightings:[...state.pendingSightings,action.payload],
                count:state.pendingSightings.length + 1,
            }
        },
        removePendingSightingLocation: (state,action) => {
            console.log("payload: ", action.payload.docid);
            return{
                ...state,
                pendingSightings: state.pendingSightings.filter((doc) => doc.docid != action.payload.docid),
                count:state.pendingSightings.length - 1,
            }
        },
        logOutAndRemoveAllpendingSightings: (state) => {
            return {
                ...state,
                pendingSightings:[],
                count:0
            }
        }
      }
  })
  
  // Action creators are generated for each case reducer function
  export const { addPendingSightingLocations,removePendingSightingLocation,logOutAndRemoveAllpendingSightings } = pendingSightingLocationSlide.actions
  export default pendingSightingLocationSlide.reducer