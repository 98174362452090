import React from 'react';
import { Col, Container, Row } from 'reactstrap';
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
// import Watchlist from './Watchlist';
// import PortfolioStatistics from './PortfolioStatistics';
// import MarketStatus from './MarketStatus';
import Widgets from './Widgets';
import MapContainer from '../../../component/MapContainer';
import Widget2 from './Widget2';


const MyWallet = (props) => {
    return (
        <React.Fragment>
            {/* <div className="page-content"> */}
                {/* <MetaTags>
                    <title>My Wallet | Velzon - React Admin & Dashboard Template</title>
                </MetaTags> */}
                <Container fluid>
                    {/* approved -> green tag with approved written */}
                    {/* pending -> red tag with rejected written */}
                    {/* pending -> blue tag with pending written */}
                    <h2 className='mt-2' style={{display:'flex', justifyContent:'start', alignItems:'center'}} > {props.location.title} <span className={props.location.type == 1 ? `badge bg-success` : props.location.type == 2 ? `badge bg-danger` : `badge bg-info`} style={{fontSize:"13px", marginLeft:'5px'}}> <center> {props.location.type == 1 ? "Approved" : props.location.type == 2 ? "Rejected": "Pending"} </center></span> </h2>
                <Row>
                        <Col xxl={6}>
                            {/* <PortfolioStatistics /> */}
                            <div className='mt-4'>
                                <MapContainer lat={props.location.lat} lng={props.location.lng} isMarker={true} isSingleSighting={true} />
                            </div>
                            {/* <Watchlist /> */}
                            {/* <MarketStatus /> */}
                        </Col>
                        <Col xxl={3}>
                            <Widgets location={props.location} type={props.type} />
                            {/* <RecentTransaction /> */}
                        </Col>
                        <Col xxl={3}>
                            <Widget2 location={props.location} type={props.type} />
                            {/* <RecentTransaction /> */}
                        </Col>
                    </Row>
                </Container>
            {/* </div> */}
        </React.Fragment>
    );
};

export default MyWallet;
