import React from 'react'
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store, persistor, sagaMiddleware } from './store';
import rootSaga from './store/sagas'
import App from './App';
import { PersistGate } from 'redux-persist/integration/react';

sagaMiddleware.run(rootSaga);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={ store }>
    <PersistGate loading={null} persistor={persistor}>	  
      <App/>
    </PersistGate>
  </Provider>
);