import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";

// const config = {
//   // apiKey: "AIzaSyAyTxkAYoK5FBe1QeQ7aTLXwL44nLsFa7A", 
//   apiKey: "AIzaSyCsgNAjkcKp9HxcUCDKYXPBxehET9Sd89o", 
//   authDomain: "crocwatch-e5c57.firebaseapp.com",
//   projectId: "crocwatch-e5c57",
//   storageBucket: "crocwatch-e5c57.appspot.com",
//   messagingSenderId: "16815040662",
//   appId: "1:16815040662:web:9b9a47fa97bfac12e47ab7",
//   measurementId: "G-2W0EQPTM1N"
// };

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API,
  authDomain: "crocwatch-e5c57.firebaseapp.com",
  projectId: "crocwatch-e5c57",
  storageBucket: "crocwatch-e5c57.appspot.com",
  messagingSenderId: "16815040662",
  appId: "1:16815040662:web:9b9a47fa97bfac12e47ab7",
  measurementId: "G-2W0EQPTM1N"
};

export const app = initializeApp(config);
export const database = getFirestore(app);
export const storage = getStorage(app);