import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const Footer = () => {
    return (
        <React.Fragment>
            {/* <footer className="footer">
                <Container fluid>
                    <Row>
                        <Col sm={6}>
                            {new Date().getFullYear()} © VNC.
                        </Col>
                        <Col sm={12}>
                            <div className="text-sm-center d-none d-sm-block">
                                Made with &#10084; by Team VNC
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer> */}

            <footer className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <p className="mb-0 text-muted">&copy; {new Date().getFullYear()} Made By VNC Tech Team</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
        </React.Fragment>
    );
};

export default Footer;