import * as Yup from "yup";

export const signUpSchema = Yup.object({
    firstName:Yup.string().required("firstName is required").min(2, "first name must contain minimum 2 characters"),
    lastName:Yup.string().required("lastName is required").min(2,"last name must contain minimum 2 characters"),
    email:Yup.string().email().required("email is required"),
    // password:Yup.string().required("password is required")
    password: Yup.string().required("password is required").matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        "password must Contain 8 Characters, One Uppercase, One Lowercase, One Number"
      )
})