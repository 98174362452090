import React, { useState, useEffect } from 'react'

// redux imports
import { useSelector, useDispatch } from 'react-redux'
import { removePendingSightingLocation } from '../features/sightingLocation/pendingSightingLocation';

// basic ui import (theme)
import MyWallet from "../pages/Crypto/MyWallet"
import { Modal, ModalBody, ModalHeader, Input, Button} from 'reactstrap';

// custom notification message
import { toast } from 'react-toastify';

// firebase imports
import { database } from '../config';
import { addDoc, collection, deleteDoc, doc, getDoc } from 'firebase/firestore';

// navigation import
import { useNavigate } from 'react-router-dom';

const Location = () => {
  const navigate = useNavigate(); // Used for url navigation (replacement of a tag)
  const dispatch = useDispatch(); // Used to call redux functions

  const userEmail = useSelector(state => state.user.userEmail); // current active user (Logged In)

  // modal states to open and close modal
  const [approveModal, setApproveModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);

  // string message state while rejecting Sight
  const [rejectionMessage, setRejectionMessage] = useState('');

  // toggle approve modal
  const tog_center_approve = () => {
    setApproveModal(!approveModal)
  }
  
  // toggle reject modal
  const tog_center_reject = () => {
    setRejectModal(!rejectModal)
  }

  // state to hold current sighting data
  const [currentSight, setCurrentSight] = useState({});

  // fetch currrent document details
  const docId = useSelector(state => state.singleLocation.docid)
  const type = useSelector(state => state.singleLocation.type)

  // fetch user role to showcase approved and rejected buttons
  const userRole = useSelector(state => state.user.userRole)

  // get all sights from redux
  let approvedSights = useSelector(state => state.approvedSightingLocation.approvedSightings);
  let rejectedSights = useSelector(state => state.rejectedSightingLocation.rejectedSightings);
  let pendingSights = useSelector(state => state.pendingSightingLocation.pendingSightings)

  // initialize current sight when first time page renders
  useEffect(() => {
    // find currentSighting in approved sightings
    approvedSights.forEach(sight => {
      if(sight.docid == docId){
        setCurrentSight(sight);
      }
    });

    // find currentSighting in rejected sightings
    rejectedSights.forEach(sight => {
      if(sight.docid == docId){
        setCurrentSight(sight);
      }
    });
  
    // find currentSighting in pending sightings
    pendingSights.forEach(sight => {
      if(sight.docid == docId){ 
        setCurrentSight(sight);
      }
    });
  }, [])

  const approveSight = async () => {
    try{
      // pending sight document ref (current document / sight)
      const pendingSightDoc = doc(database, "unapproved sightings", currentSight.docid);
      
      // approved sight collection ref
      const approvedSightRef = collection(database, "approved sightings");
      
      // fetch pending sight doc from ref
      const docSnap = await getDoc(pendingSightDoc);
      
      if (docSnap.exists()) {
        const document = docSnap.data();
        // creating object as per approved sighting schema requirment
        const doc = {...document, created_at: {...document.created_at}, location_details: {...document.location_details}, sighting_details: {...document.sighting_details}, sighting: document.sighting,approved_by:userEmail}
        
        // creating approved document
        await addDoc(approvedSightRef, doc) 
        
        // deleting pending document (unapproved)
        await deleteDoc(pendingSightDoc); 
        
        // removing pending document from redux
        dispatch(removePendingSightingLocation({docid:currentSight.docid})); 

        // go to all pending sights (back)
        navigate("/pending-sightings")
        toast.success("Sight approved successfully", {position: "top-center"});
      } else {
        console.log("error...");
        throw new Error("Error while reading document")
      }
    } catch(error){
      console.error("err: ", error);
      toast.error("Error while approving Sight, try again later" ,{position:"top-center"})
    }
    setApproveModal(false);
  }
  
  const rejectSight = async () => {
    {/* rejection message validation */}
    if(rejectionMessage.length <= 2){
      toast.error("Please write appropriate rejection reason", {position: "top-center"})
      return;
    }
    try{
      // pending sight document ref
      const pendingSightDoc = doc(database, "unapproved sightings", currentSight.docid);
      
      // rejected sight collection ref
      const rejectedSightRef = collection(database, "rejected sightings");
      
      // fetch pending sight from ref
      const docSnap = await getDoc(pendingSightDoc);
      
      if (docSnap.exists()) {
        const document = docSnap.data();
        // creating object as per rejected sighting schema requirment
        const doc = {...document, created_at: {...document.created_at}, location_details: {...document.location_details}, sighting_details: {...document.sighting_details}, sighting: document.sighting, reason_for_rejecting: rejectionMessage,rejected_by:userEmail}
        
        // creating rejected document
        await addDoc(rejectedSightRef, doc) 
        
        // deleting pending document (unapproved)
        await deleteDoc(pendingSightDoc); 
        
        // removing pending document from redux
        dispatch(removePendingSightingLocation({docid:currentSight.docid})); 
        
        // go to pendinghs sights page (back)
        navigate("/pending-sightings")
        toast("Sight rejected successfully", { position: "top-center" })
      } else {
        console.log("error...");
        throw new Error("Error while reading document")
      }
    } catch(error){
      console.error("err: ", error);
      toast.error("Error while approving Sight, try again later" ,{position:"top-center"})
    }
   // close reject modal
    setRejectModal(false);
  }


 
  return (
    <React.Fragment>
      {/* approve modal start*/}
    <Modal
        isOpen={approveModal}
        toggle={() => {
            tog_center_approve();
        }}
        centered
    >
        <ModalHeader className="modal-title" />
        <ModalBody className="text-center p-5">
            <lord-icon src="https://cdn.lordicon.com/hrqwmuhr.json"
                trigger="loop" colors="primary:#121331,secondary:#08a88a" style={{ width: "120px", height: "120px" }}>
            </lord-icon>
            <div className="mt-4">
                <h4 className="mb-3">Select approve to approve the sighting.</h4>
                {/* <p className="text-muted mb-4"> The transfer was not successfully received by us. the email of the recipient wasn't correct.</p> */}
                <div className="hstack gap-2 justify-content-center">
                    <Button className="btn btn-success"  onClick={() => approveSight()}>Approve</Button>
                    <Button className="btn btn-primary"  onClick={() => setApproveModal(false)}>Cancel</Button>
                </div>
            </div>
        </ModalBody>
    </Modal>
    {/* approve modal finish*/}

    {/* reject modal start*/}
    <Modal
        isOpen={rejectModal}
        toggle={() => {
            tog_center_reject();
        }}
        centered
    >
        <ModalHeader className="modal-title" />

        <ModalBody className="text-center p-5">
            <lord-icon src="https://cdn.lordicon.com/hrqwmuhr.json"
                trigger="loop" colors="primary:#121331,secondary:#08a88a" style={{ width: "120px", height: "120px" }}>
            </lord-icon>
            <div className="mt-4">
                <h4 className="mb-3">Enter why do you want to reject sighting.</h4>
                {/* input field for reason of rejected */}
                <div className="mb-3">
                    {/* <Label htmlFor="email" className="form-label">Email</Label> */}
                    <Input
                        name="text"
                        className="form-control"
                        placeholder="Enter reason for rejection"
                        type="text"
                        onChange={(e) => setRejectionMessage(e.target.value)}
                        value={rejectionMessage}
                        invalid={
                            rejectionMessage.length < 2 ? true : false
                        }
                    />
                </div>
                <div className="hstack gap-2 justify-content-center">
                    <Button className="btn btn-danger"  onClick={() => rejectSight()}>Reject</Button>
                    <Button className="btn btn-primary"  onClick={() => setRejectModal(false)}>Cancel</Button>
                </div>
            </div>
        </ModalBody>
    </Modal>
    {/* reject modal finish*/}


      <div className='page-content'>
        {currentSight && <MyWallet location={currentSight} type={type} />}
        <div className='d-flex justify-content-center align-items-center my-4'>
          <div>
          {/* user role is moderator and type of sight is pending then showcase these buttons */}
          {(userRole == "Moderator" && type == 3) && <button type="button" className="btn btn-outline-success waves-effect waves-light mx-2" onClick={() => setApproveModal(true)}>Approve Sighting</button>}
          {(userRole == "Moderator" && type == 3) && <button type="button" className="btn btn-outline-danger waves-effect waves-light mx-2"  onClick={() => setRejectModal(true)}>Reject Sighting</button>}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Location
