import React from "react";
import { Col, Container, Row } from "reactstrap";
// import BreadCrumb from "../../Components/Common/BreadCrumb";
// import MetaTags from 'react-meta-tags';

import Widget from "./Widgets";
// import UserWidget from "./UserWidget";


// import BestSellingProducts from "./BestSellingProducts";
// import RecentActivity from "./RecentActivity";
// import RecentOrders from "./RecentOrders";
// import Revenue from "./Revenue";
// import SalesByLocations from "./SalesByLocations";
// import Section from "./Section";
// import StoreVisits from "./StoreVisits";
// import TopSellers from "./TopSellers";

import HomePageMap from "../../component/HomePageMap";

const DashboardEcommerce = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <MetaTags>
            <title>Dashboard | Velzon - React Admin & Dashboard Template</title>
          </MetaTags> */}
          {/* <BreadCrumb title="Dashboard" pageTitle="Dashboards" /> */}
          <Row>
            <Col>
              <div className="h-100">
                {/* Good morning anna section */}
                {/* <Section /> */}
                <Row>
                  <Widget />
                </Row>
                <Row>
                  <Col xl={18} md={9}>
                    {/* <Revenue /> */}
                    <div className="my-2">
                      <HomePageMap/>
                    </div>
                  </Col>
                  {/* <UserWidget /> */}
                </Row>
                {/* <Row>
                  <BestSellingProducts />
                  <TopSellers />
                </Row>
                <Row>
                  <StoreVisits />
                  <RecentOrders />
                </Row> */}
              </div>
            </Col>
            {/* <RecentActivity /> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardEcommerce;
