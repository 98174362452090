import React, { useState } from "react";

// navigation imports
import { Link, useNavigate } from "react-router-dom";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import { addSight } from "../../../features/sightingLocation/singleLocation";
import { removePendingSightingLocation } from "../../../features/sightingLocation/pendingSightingLocation";

// UI imports (theme)
import { Modal, ModalBody, Button, Input, ModalHeader } from 'reactstrap';

// custom toast messages import
import { toast } from "react-toastify";

// firebase database imports
import { database } from "../../../config";
import { addDoc, collection, deleteDoc, doc, getDoc } from 'firebase/firestore';


// const OrdersId = (cell) => {
//     return (
//         <React.Fragment>
//             <Link to="/apps-tasks-details" className="fw-medium link-primary">{cell.value}</Link>
//         </React.Fragment>
//     );
// };

// const Project = (cell) => {
//     return (
//         <React.Fragment>
//             <Link to="/apps-projects-overview" className="fw-medium link-primary">{cell.value}</Link>
//         </React.Fragment>
//     );
// };

// used for city, habitat, title crocodile count and user (react component)
const Tasks = (cell) => {
    return (
        <React.Fragment>
            {cell.value}
        </React.Fragment>
    );
};

// function to get current sight from all sightings
const getCurrentSight = (docId, allSightings) => {
    let temp = {};
    allSightings.forEach(sight => {
        if(sight.docid == docId){
        temp = sight;
        }
    });
    return temp;
}

// open sighting (view details button component and functionality)
const OpenInMap = (cell) => {
    // cell.type = 1, 2, 3, 4 (as oer approved, rejected, pending and all sightings) 

    // fetch all pending location from redux
    let pendingSights = useSelector(state => state.pendingSightingLocation.pendingSightings)

    // a tag alternative
    const navigate = useNavigate();

    // dispatch is used to dispatch redux actions
    const dispatch = useDispatch();

    // fetch user role from redux
    const userRole = useSelector(state => state.user.userRole)

    // fetch type of sight from store (redux)

    // fetch user email from redux (used for approval and rejection of sighting)
    const userEmail = useSelector(state => state.user.userEmail)

    // approve modal state
    const [approveModal, setApproveModal] = useState(false);
    
    // reject modal state
    const [rejectModal, setRejectModal] = useState(false);

    // rejection message state
    const [rejectionMessage, setRejectionMessage] = useState('')

    // toggle approve modal state (used to close the modal whenever uses clicks outside of modal)
    const tog_center_approve = () => {
        setApproveModal(!approveModal)
    }
    
    // toggle reject modal state (used to close the modal whenever uses clicks outside of modal)
    const tog_center_reject = () => {
        setRejectModal(!rejectModal)
    }

    // function to approve sight (pending)
    const approveSight = async () => {
        let docId = cell.value;
        
        // fetch currentSight from pending sight
        let currentSight = getCurrentSight(docId, pendingSights);
        
        try{
            // create curernt pending sight document ref
            const pendingSightDoc = doc(database, "unapproved sightings", currentSight.docid);
            
            // create approved sightings collection ref
            const approvedSightRef = collection(database, "approved sightings");
            
            // fetch current pending sight from the ref
            const docSnap = await getDoc(pendingSightDoc);
            
            if (docSnap.exists()) {
                const document = docSnap.data();

                // create object as per requirment of approved sighting schema
                const doc = {...document, created_at: {...document.created_at}, location_details: {...document.location_details}, sighting_details: {...document.sighting_details}, sighting: document.sighting,approved_by:userEmail}
                
                // creating approved document
                await addDoc(approvedSightRef, doc) 
                
                // deleting pending document (unapproved)
                await deleteDoc(pendingSightDoc); 
                
                // removing pending document from redux
                dispatch(removePendingSightingLocation({docid:currentSight.docid}));

                navigate("/pending-sightings") // back navigation
                toast.success("Sight approved successfully", {position: "top-center"});
            } else {
                throw new Error("Error while reading document")
            }
        } catch(error){
            toast.error("Error while approving Sight, try again later" ,{position:"top-center"})
        }
        setApproveModal(false); // close modal
    }

    const rejectSight = async () => {
        let docId = cell.value;

        // fetch current pending sight
        let currentSight = getCurrentSight(docId, pendingSights);

        // rejection message input validation
        if(rejectionMessage.length <= 2){
          toast.error("Please write appropriate rejection reason", {position: "top-center"})
          return;
        }

        try{
            // create current pending sight document ref
            const pendingSightDoc = doc(database, "unapproved sightings", currentSight.docid);
            
            // create rejected sightings collection ref
            const rejectedSightRef = collection(database, "rejected sightings");

            // fetch current pending sight from the firebase database
            const docSnap = await getDoc(pendingSightDoc);
            
            if (docSnap.exists()) {
                const document = docSnap.data();

                // create object as per rejected sightings collection schema
                const doc = {...document, created_at: {...document.created_at}, location_details: {...document.location_details}, sighting_details: {...document.sighting_details}, sighting: document.sighting, reason_for_rejecting: rejectionMessage,rejected_by:userEmail}
                
                // creating rejected document
                await addDoc(rejectedSightRef, doc) 
                
                // deleting pending document (unapproved)
                await deleteDoc(pendingSightDoc); 
                
                // removing pending document from redux
                dispatch(removePendingSightingLocation({docid:currentSight.docid})); 
                navigate("/pending-sightings") // back navigation
                toast("Sight rejected successfully", { position: "top-center" })
            } else {
                throw new Error("Error while reading document")
            }
        } catch(error){
          toast.error("Error while approving Sight, try again later" ,{position:"top-center"})
        }
        setRejectModal(false);
    }

    // fucntion to open sight using sight documentId and type of sight
    const openSighting = (docid, sightType) => {
        const singleLocation = {docid, type:sightType}

        // add current sighting location in single sighting feature (redux)
        dispatch(addSight(singleLocation))

        // open sight that is added in redux
        navigate("/location");
    }


    return (
        <>
        {/* approve modal start */}
        <Modal isOpen={approveModal}
            toggle={() => {
                tog_center_approve();
            }}
            centered
        >
        <ModalHeader className="modal-title" />

        <ModalBody className="text-center p-5">
            <lord-icon src="https://cdn.lordicon.com/hrqwmuhr.json"
                trigger="loop" colors="primary:#121331,secondary:#08a88a" style={{ width: "120px", height: "120px" }}>
            </lord-icon>
            <div className="mt-4">
                <h4 className="mb-3">Select approve to approve the sighting.</h4>
                {/* <p className="text-muted mb-4"> The transfer was not successfully received by us. the email of the recipient wasn't correct.</p> */}
                <div className="hstack gap-2 justify-content-center">
                    <Button className="btn btn-success"  onClick={() => approveSight()}>Approve</Button>
                    <Button className="btn btn-primary"  onClick={() => setApproveModal(false)}>Cancel</Button>
                </div>
            </div>
        </ModalBody>
        </Modal>
        {/* approve modal close */}

        {/* reject modal start */}
        <Modal
            isOpen={rejectModal}
            toggle={() => {
                tog_center_reject();
            }}
            centered
        >
            <ModalHeader className="modal-title" />

            <ModalBody className="text-center p-5">
                <lord-icon src="https://cdn.lordicon.com/hrqwmuhr.json"
                    trigger="loop" colors="primary:#121331,secondary:#08a88a" style={{ width: "120px", height: "120px" }}>
                </lord-icon>
                <div className="mt-4">
                    <h4 className="mb-3">Enter why do you want to reject sighting.</h4>
                    {/* input field for reason of rejected */}
                    <div className="mb-3">
                        {/* <Label htmlFor="email" className="form-label">Email</Label> */}
                        <Input
                            name="text"
                            className="form-control"
                            placeholder="Enter reason for rejection"
                            type="text"
                            onChange={(e) => setRejectionMessage(e.target.value)}
                            value={rejectionMessage}
                            invalid={
                                rejectionMessage.length < 2 ? true : false
                            }
                        />
                    </div>
                    <div className="hstack gap-2 justify-content-center">
                        <Button className="btn btn-danger"  onClick={() => rejectSight()}>Reject</Button>
                        <Button className="btn btn-primary"  onClick={() => setRejectModal(false)}>Cancel</Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
        {/* reject modal close */}


    {/* sight must be pending in order to approve or reject it */}
    {/* error */}
    {userRole == "Moderator" && cell.type == 3 ? <div className="d-flex">
            <div className={`avatar-sm flex-shrink-0 mx-1 ${cell.value}`} style={{cursor:"pointer"}} onClick={() => {openSighting(cell.value, cell.type)}}>
            <span className={"avatar-title rounded fs-3 bg-soft-primary"}>
                <i className={`text-primary bx bx-bullseye`}></i>
            </span>
            </div>

            <div className={`avatar-sm flex-shrink-0 mx-1 ${cell.value}`} style={{cursor:"pointer"}}  onClick={() => setApproveModal(true)}>
            <span className={"avatar-title rounded fs-3 bg-soft-success"}>
                <i className={`text-success bx bx-check`}></i>
            </span>
            </div>
        
            <div className={`avatar-sm flex-shrink-0 mx-1 ${cell.value}`} style={{cursor:"pointer"}}  onClick={() => setRejectModal(true)}>
            <span className={"avatar-title rounded fs-3 bg-soft-danger"}>
                <i className={`text-danger bx bx-x`}></i>
            </span>
            </div>
        </div> : <button type="button" onClick={() => {openSighting(cell.value, cell.type)}} class="btn btn-outline-primary waves-effect waves-light">View Details</button>}
        </>
      )
}

// const CreateBy = (cell) => {
//     return (
//         <React.Fragment>
//             {cell.value}
//         </React.Fragment>
//     );
// };


// const AssignedTo = (cell) => {
//     return (
//         <React.Fragment>
//             <div className="avatar-group">
//                 {cell.value.map((item, index) => (
//                     <Link key={index} to="#" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Alexis">
//                         <img src={item.img} alt="" className="rounded-circle avatar-xxs" />
//                     </Link>
//                 ))}
//             </div>
//         </React.Fragment>
//     );
// };

// const DueDate = (cell) => {
//     return (
//         <React.Fragment>
//             {cell.value}
//         </React.Fragment>
//     );
// };

// const Status = (cell) => {
//     return (
//         <React.Fragment>
//             {cell.value === "pending" ?
//                 <span className="badge badge-soft-secondary text-uppercase">{cell.value}</span>
//                 : cell.value === "completed" ?
//                     <span className="badge badge-soft-success text-uppercase">{cell.value}</span>
//                     : cell.value === "rejected" ?
//                         <span className="badge badge-soft-warning text-uppercase">{cell.value}</span>
//                         : null
//             }
//         </React.Fragment>
//     );
// };

// const Priority = (cell) => {
//     return (
//         <React.Fragment>
//             {cell.value === "Medium" ?
//                 <span className="badge bg-warning text-uppercase">{cell.value}</span>
//                 :
//                 cell.value === "High" ?
//                     <span className="badge bg-danger text-uppercase">{cell.value}</span>
//                     : cell.value === "Low" ?
//                         <span className="badge bg-success text-uppercase">{cell.value}</span>
//                         : null
//             }
//         </React.Fragment>
//     );
// };


export { Tasks, OpenInMap };