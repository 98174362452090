import { createSlice } from "@reduxjs/toolkit";

// 0 for not set
// 1 for approved
// 2 for rejected
// 3 for unapproved

export const singleLocationSlide = createSlice({
    name: 'singleLocation',
    initialState: {
        docid:"",
        type:0
    },
    reducers: {
        addSight: (state,action) => {
          return{
                ...state,
                docid:action.payload.docid,
                type:action.payload.type,
            }
        },
        removeSight: (state,payload) => {
            return{
                ...state,
                docid:"",
                type:0
            }
        }
      }
  })
  
  // Action creators are generated for each case reducer function
  export const { addSight,removeSight } = singleLocationSlide.actions
  export default singleLocationSlide.reducer