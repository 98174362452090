// hand writtern css
import './App.css';

// velzon theme's scss
import './assets/scss/themes.scss';

// toast library to show fancy alert messages
import { ToastContainer } from 'react-toastify';

// routing import
import { HashRouter, Routes, Route } from 'react-router-dom';

// redux import
import { useSelector } from 'react-redux'

// user written components
// import Login from './component/Login';
// import SignUp from './component/SignUp';
// import HomePage from './component/HomePage';
// import Navbar from './component/Navbar';
// import Submission from './component/Submission';
// import ProfileImage from './component/ProfileImage';
// import Profile from './component/Profile';
// import EditProfile from './component/EditProfile';

// theme layout for the website
import VerticalLayout from "./Layouts/VerticalLayouts"

// user defined components
import Sightings from './component/Sightings';
import Location from './component/Location';

// velzon theme components
import Settings from './pages/Pages/Profile/Settings/Settings';
import SimplePage from './pages/Pages/Profile/SimplePage/SimplePage';
import Login from './pages/Authentication/Login';
import SignUp from './pages/Authentication/Register';
import ForgetPassword from './pages/Authentication/ForgetPassword';
import HomePage from './pages/DashboardEcommerce'



function App() {
  const isActive =  useSelector(state => state.user.isActive)
  if(!isActive){
    return (
      <>
      <HashRouter>
        <Routes>
          <Route exact path="/signup" element={<SignUp/>} /> {/* signup component */}
          <Route exact path="/forgot-password" element={<ForgetPassword/>} /> {/* forgot password component */}
          <Route path="/*" element={<Login/>}></Route> {/* render login component for all other urls */}
        </Routes>
        <ToastContainer/> {/* custom toast message component */}
      </HashRouter>
      </>
    );
  }
  else{
    return (
      <>
        <HashRouter> {/* base name for github pages deployment */}
          <VerticalLayout>
            <Routes>
              <Route exact path="/profile" element={<SimplePage/>} /> {/* User profile component */}
              <Route exact path="/edit-profile" element={<Settings/>} /> {/* Edit profile component */}
              <Route exact path="/forgot-password" element={<ForgetPassword/>} /> {/* Forgot Password Component */}
              <Route exact path="/approved-sightings" element={<Sightings type={1} />} /> {/* Approved Sighting table component */}
              <Route exact path="/rejected-sightings" element={<Sightings  type={2}/>} /> {/* Rejected Sighting table component */}
              <Route exact path="/pending-sightings" element={<Sightings  type={3}/>} /> {/* Pending Sighting table component */}
              <Route exact path="/all-sightings" element={<Sightings  type={4}/>} /> {/* All Sighting table component */}
              <Route exact path="/location" element={<Location />} /> {/* Single Sight info Component */}
              <Route exact path="/*" element={<HomePage/>} /> {/* render Home page component for all other urls */}
            </Routes>
            <ToastContainer/> {/* custom toast message component */}
          </VerticalLayout>
        </HashRouter>
      </>
    );
  }
}

export default App;

// "homepage": "https://manavshah47.github.io/VNC_CROCWATCH",
