import React from 'react'
import {GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";

const MapContainer = (props) => {
    const { isLoaded } = useLoadScript({ googleMapsApiKey: "AIzaSyAoNxbIfUUWe5KK36WF8y5ejuj0KmlUx6I"}); // we will use env file to store API keys
    
    if(!isLoaded){
        return <div>Loading...</div>
    }
    else{
        return (
            <>
            <div>
                <GoogleMap 
                    options={{streetViewControl:false,zoomControl:true}} 
                    center={{lat:parseFloat(props.lat),lng: parseFloat(props.lng)}} 
                    zoom={props?.isSingleSighting ? 18 :13} // for single sighting location, zoom will be increased
                    mapContainerClassName="map-container-2"
                >
                    {props.isMarker && <MarkerF position={{lat:parseFloat(props.lat),lng: parseFloat(props.lng)}}/>}
                </GoogleMap>
            </div>
        </>
        )
    }
}


export default MapContainer