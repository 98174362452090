import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    isActive:false,
    firstName: "",
    lastName: "",
    profileImage:"",
    userBio:"",
    userRole:"",
    userEmail:"",
    userPhone:"",
    socialmedia: {
      facebook:"",
      instagram:"",
      linkedIn:""
    }
  },
  reducers: {
    initializeUser: (state,action) => {
      return {
        ...state,
        isActive:true,
        profileImage:action.payload.profileImage,
        userBio:action.payload.userBio,
        firstName:action.payload.firstName,
        lastName:action.payload.lastName,
        userRole:action.payload.userRole,
        userEmail:action.payload.userEmail,
        userPhone: action.payload.userPhone,
        socialmedia: action.payload.socialmedia
      }
    },
    logOutUser: (state) => {
      return {
        ...state,
        isActive:false,
        profileImage:"",
        userBio:"",
        firstName:"",
        lastName:"",
        userRole:"",
        userEmail:""
      }
    }
  }
})

// Action creators are generated for each case reducer function
export const { initializeUser, logOutUser } = userSlice.actions

export default userSlice.reducer