import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

import userReducer from '../features/user/userSlice'
import approvedSightingLocationReducer from '../features/sightingLocation/approvedsightingLocationSlice'
import rejectedSightingLocationReducer from '../features/sightingLocation/rejectedSightingLocationSlice'
import singleLocation from "../features/sightingLocation/singleLocation";
import pendingSightingLocation from "../features/sightingLocation/pendingSightingLocation";

// // Authentication
// import Login from "./auth/login/reducer";
// import Account from "./auth/register/reducer";
// import ForgetPassword from "./auth/forgetpwd/reducer";
// import Profile from "./auth/profile/reducer";

// //Calendar
// import Calendar from "./calendar/reducer";
// //Chat
// import chat from "./chat/reducer";
// //Ecommerce
// import Ecommerce from "./ecommerce/reducer";

// //Project
// import Projects from "./projects/reducer";

// // Tasks
// import Tasks from "./tasks/reducer";
// //Form advanced
// import changeNumber from "./formAdvanced/reducer";

// //Crypto
// import Crypto from "./crypto/reducer";

// //TicketsList
// import Tickets from "./tickets/reducer";
// //Crm
// import Crm from "./crm/reducer";

// //Invoice
// import Invoice from "./invoice/reducer";


const rootReducer = combineReducers({
    // public
    Layout,
    user:userReducer,
    approvedSightingLocation:approvedSightingLocationReducer,
    rejectedSightingLocation:rejectedSightingLocationReducer,
    pendingSightingLocation: pendingSightingLocation,
    singleLocation:singleLocation
});

export default rootReducer;