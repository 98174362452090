import React from 'react';
import {GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import { useSelector } from 'react-redux';

import MapContainer from './MapContainer';

const HomePageMap = () => {

  // load map using map api key
  const { isLoaded } = useLoadScript({ googleMapsApiKey: "AIzaSyAoNxbIfUUWe5KK36WF8y5ejuj0KmlUx6I" }); // we will use env file to store API keys
  
  // fetch approved and rejected sightings
  let approvedDocs = useSelector(state => state.approvedSightingLocation.approvedSightings)
  let rejectedDocs = useSelector(state => state.rejectedSightingLocation.rejectedSightings)
  let allDocs = approvedDocs.concat(rejectedDocs)

  // VNC office lat and lng (if no approved or rejected sighting is there for user, then vnc office will be given as center of the map)
  const center = {
    lat:22.54734,
    lng:72.92859
  }

  if(!isLoaded) { // better loading container is needed
    return <div>Loading...</div>
  } else {
    return (
      <>
      {/* if approved or rejected sighting is there, then show markers */}
      { allDocs.length > 0 ?  (
        <>
        <div className='card card-animate'>
          <GoogleMap options={{streetViewControl:false,zoomControl:true}} mapContainerClassName="map-container"
            onLoad={(map) => {
              {/* bounds are used to get center of all markers */}
              const bounds = new window.google.maps.LatLngBounds();
              allDocs.forEach((location) => {
                bounds.extend({lat:parseFloat(location.lat),lng:parseFloat(location.lng)});
              })
            map.fitBounds(bounds); 
            }}
          >

            {/* approved and rejected sightings marker */}
          {allDocs.map((location) => {
            return <MarkerF key={location.docid} position={{lat: parseFloat(location.lat),lng: parseFloat(location.lng)}}/>
          })}
          
          </GoogleMap>
        </div>
        </>
      ) :  (
          <>
          {/* no approved or rejected sighting condition */}
          <div className=''>
            <MapContainer lat={center.lat} lng={center.lng} isMarker={false} />
          </div>
          </>
        )
      }
      </>
    )
  }
}



export default HomePageMap
