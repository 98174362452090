import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth
// import AccountSaga from "./auth/register/saga";
// import AuthSaga from "./auth/login/saga";
// import ForgetSaga from "./auth/forgetpwd/saga";
// import ProfileSaga from "./auth/profile/saga";

// //calendar
// import calendarSaga from "./calendar/saga";
// //chat
// import chatSaga from "./chat/saga";
// //ecommerce
// import ecommerceSaga from "./ecommerce/saga";

// //Project
// import projectSaga from "./projects/saga";
// // Task
// import taskSaga from "./tasks/saga";
// //Counter
// // import CounterSaga from './formAdvanced/saga';
// // Crypto
// import cryptoSaga from "./crypto/saga";
// //TicketsList
// import ticketsSaga from "./tickets/saga";

// //crm
// import crmSaga from "./crm/saga";
// //invoice
// import invoiceSaga from "./invoice/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga)
  ]);
}
