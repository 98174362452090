import React, { useEffect, useState } from 'react';
import CountUp from "react-countup";
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';
// import { ecomWidgets } from "../../common/data";
import { useSelector } from 'react-redux';


const Widgets = () => {

    // fetch sightings from redux
    const approvedSightingsCount = useSelector(state => state.approvedSightingLocation.count)
    const rejectedSightingsCount = useSelector(state => state.rejectedSightingLocation.count)
    const pendingSightingsCount = useSelector(state => state.pendingSightingLocation.count)
    
    // fetch userRole from redux (used to determine whether show pending sights or not)
    const userRole = useSelector(state => state.user.userRole)

    const allSightingCount = rejectedSightingsCount + approvedSightingsCount;

    // finding percentage of approved and rejected sightings
    let approvedPercentage = (approvedSightingsCount*100/allSightingCount).toFixed(2);
    let rejectedPercentage = (rejectedSightingsCount*100/allSightingCount).toFixed(2);

    // if no sightings are there then percentage gives NaN
    if(isNaN(approvedPercentage)){
        approvedPercentage = 0;
    }

    if(isNaN(rejectedPercentage)){
        rejectedPercentage = 0;
    }

    // Extract user Info from redux
    // const firstName = useSelector(state => state.user.firstName)
    // const lastName = useSelector(state => state.user.lastName)
    // const userRole = useSelector(state => state.user.userRole)
    // const userBio = useSelector(state => state.user.userBio)
    // const profileImage = useSelector(state => state.user.profileImage)
    // const userName = firstName + " " + lastName



    const sightingData = [
        {
            id: 1,
            label: "All sightings",
            counter: allSightingCount,
            link: "View all sightings",
            href: "/all-sightings",
            bgcolor: "warning",
            icon: "bx bx-map"
        },
        {
            id: 2,
            label: "Approved sightings",
            badge: "ri-arrow-right-up-line",
            badgeClass: "success",
            percentage: approvedPercentage,
            counter: approvedSightingsCount,
            link: "View approved sightings",
            href: "/approved-sightings",
            bgcolor: "success",
            icon: "bx bx-check"
        },
        {
            id: 3,
            label: "Rejected sightings",
            badge: "ri-arrow-right-up-line",
            badgeClass: "danger",
            percentage: rejectedPercentage,
            counter: rejectedSightingsCount,
            link: "View rejected sightings",
            href: "/rejected-sightings",
            bgcolor: "danger",
            icon: "bx bx-x"
        }
        // {
        //     id:"4",
        //     label: "User Info",
        //     userName: userName, 
        //     userBio: userBio,
        //     userRole: userRole,
        //     bgcolor: "primary",
        //     icon: "bx bx-user",
        //     link: "View user profile",
        //     href: "/profile",
        //     profileImage:profileImage
        // }
    ]

    if(userRole == "Moderator"){
        sightingData.push({
            id: 4,
            label: "pending sightings",
            badge: "ri-arrow-right-up-line",
            badgeClass: "primary",
            counter: pendingSightingsCount,
            link: "View pending sightings",
            href: "/pending-sightings",
            bgcolor: "warning",
            icon: "bx bx-hourglass"
        })
    }




    return (
        <React.Fragment>
            {sightingData.map((item, key) => (
                <Col xl={3} md={6} key={key}>
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex align-items-center">
                                {item.profileImage && 
                                    <div className='profile-image'>
                                        <img src={item.profileImage} className='profile-img'></img>
                                    </div>
                                }

                                <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{item.label}</p>
                                </div>
                                <div className="flex-shrink-0">
                                    <h5 className={"fs-14 mb-0 text-" + item.badgeClass}>
                                        {item.percentage && <span>{item.percentage} % </span>}
                                    </h5>
                                </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                        <span className="counter-value" data-target="559.25">
                                            {item.counter != 0 ? <CountUp
                                                start={0}
                                                prefix={item.prefix}
                                                suffix={item.suffix}
                                                separator={item.separator}
                                                end={item.counter}
                                                decimals={item.decimals}
                                                duration={4}
                                            /> : 0}
                                        </span>
                                    </h4>
                                    <h4 className="fs-20 fw-semibold ff-secondary">
                                            {item.userName}
                                    </h4>
                                    <h5 className="fs-12 fw-medium text-muted">
                                            {item.userRole}
                                    </h5>
                                    <h5 className="fs-12 fw-medium text-muted">
                                            {item.userBio}
                                    </h5>
                                    <Link to={item.href} className="text-decoration-underline">{item.link}</Link>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                    <span className={"avatar-title rounded fs-3 bg-soft-" + item.bgcolor}>
                                        <i className={`text-${item.bgcolor} ${item.icon}`}></i>
                                    </span>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            ))}

        </React.Fragment>
    );
};

export default Widgets;