import React from 'react';
import { Card, CardBody } from 'reactstrap';

const Widgets = (props) => {

    const type = (props.type == 1 ? "Approved" : props.type == 2 ? "Rejected" : props.type == 3 ? "Pending" : "All");

    return (
        <React.Fragment>
        <div className='mt-4'>
            <Card>
                <CardBody className="bg-soft-primary">
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <h5 className="fs-13 mb-3 text-muted">Type of Sighting</h5>
                            <h3>{props.location.type_of_sighting}</h3>
                            <p className="text-muted mb-0"> {props.type == 3 ? "User: " + props.location.user : null } &nbsp; </p>
                        </div>
                        <div className="flex-shrink-0">
                            <i className="mdi mdi-wallet-outline text-primary h1"></i>
                        </div>
                    </div>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <h5 className="fs-13 mb-3 text-muted">Habitat</h5>
                            <h3>{props.location.habitat}</h3>
                        </div>
                        <div className="flex-shrink-0">
                            <i className="mdi mdi-island text-primary h1"></i>
                        </div>
                    </div>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <h5 className="fs-13 mb-3 text-muted">Location</h5>
                            <h4>{props.location.city} , {props.location.state}</h4>
                            <p className="text-muted mb-0">{parseFloat(props.location.lat).toFixed(2)} , {parseFloat(props.location.lng).toFixed(2)}</p>
                        </div>
                        <div className="flex-shrink-0">
                            <i className="mdi mdi-map-marker text-primary h1"></i>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
        </React.Fragment>
    );
};

export default Widgets;