import React from 'react'
import { Card, CardBody } from 'reactstrap';

const Widget2 = (props) => { 
    return (
        <React.Fragment>
        <div className='mt-4'>
            <Card>
                <CardBody className="bg-soft-primary">
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <h5 className="fs-13 mb-3 text-muted">Crocodile Species</h5>
                            <h3>{props.location.species}</h3>
                            <p className="mb-0"> &nbsp; </p>
                        </div>
                        <div className="flex-shrink-0">
                            <i className="mdi mdi-wallet-outline text-primary h1"></i>
                        </div>
                    </div>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <h5 className="fs-13 mb-3 text-muted">Sighting Detail</h5>
                            <h2>Count: {props.location.numbers_observed}</h2>
                            <p className="text-muted mb-0"></p>
                            {props.location.spotted_at && <p className="text-muted mb-0">Spotted At: {props.location.spotted_at}</p>}
                        </div>
                        <div className="flex-shrink-0">
                            <i className="ri-line-chart-line text-primary h1"></i>
                        </div>
                    </div>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <h5 className="fs-13 mb-3 text-muted">Sighted On</h5>
                            <p className="h5 mb-1">{props.location.date}</p>
                            <p className="h5 mb-0">{props.location.time} </p>
                        </div>
                        <div className="flex-shrink-0">
                            <i className="mdi mdi-alarm text-primary h1"></i>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
        </React.Fragment>
    );
}

export default Widget2
