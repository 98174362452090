import React from 'react'

// ui import (theme)
import { Container } from 'reactstrap';
import AllTasks from '../pages/Tasks/TaskList/AllTasks';


const Sightings = (props) => {
  return (
    <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <AllTasks type={props.type}/>
                </Container>
            </div>
        </React.Fragment>
  )
}

export default Sightings
