import { createSlice } from "@reduxjs/toolkit";

export const rejectedSightingLocationSlice = createSlice({
    name: 'rejectedSightingLocation',
    initialState: {
        rejectedSightings:[],
        count:0
    },
    reducers: {
        addRejectedSightingLocations: (state,action) => {
          return{
                ...state,
                rejectedSightings:[...state.rejectedSightings,action.payload],
                count:state.rejectedSightings.length + 1 
            }
        },
        removeRejectedSightingLocations: (state,payload) => {
            return{
                ...state,
                todos: state.rejectedSighting.filter((loc) => loc.lat !== payload.lat && loc.lng !== payload.lng)
            }
        },
        logOutAndRemoveAllRejectedSightings: (state) => {
            return {
                ...state,
                rejectedSightings:[],
                count:0
            }
        }
      }
  })
  
  // Action creators are generated for each case reducer function
  export const { addRejectedSightingLocations,removeRejectedSightingLocation,logOutAndRemoveAllRejectedSightings } = rejectedSightingLocationSlice.actions
  export default rejectedSightingLocationSlice.reducer